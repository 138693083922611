/* eslint-disable no-nested-ternary */
import styled, { createGlobalStyle } from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const HeroContainer = styled.div<{
  headerHeight: number;
  viewportHeight: number;
  isMouseDown: boolean;
  scrollPosition: number;
  enableAnimation: boolean;
}>`
  display: flex;
  height: ${({ viewportHeight, headerHeight }) =>
    viewportHeight ? `${viewportHeight - headerHeight}px` : `calc(100vh - ${headerHeight}px)`};
  width: 100%;
  flex-direction: column;
  position: relative;
  margin-top: ${({ headerHeight }) => headerHeight}px;
  border-radius: 0 0 20px 20px;
  overflow: hidden;

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 0 0 30px 30px;
  }

  &:hover {
    cursor: ${({ isMouseDown, scrollPosition, enableAnimation }) =>
      enableAnimation
        ? 'progress'
        : scrollPosition > 0
        ? 'inherit'
        : isMouseDown
        ? 'grabbing'
        : 'grab'};
  }
`;

export const SliderWrapper = styled.div<{
  color?: string;
  headerHeight: number;
  viewportHeight: number;
}>`
  position: absolute;
  top: 0;
  background-color: ${({ color }) => color || '#4a4d4e'};
  display: flex;
  opacity: 0;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: ${({ viewportHeight, headerHeight }) =>
    viewportHeight ? `${viewportHeight - headerHeight}px` : `calc(100vh - ${headerHeight}px)`};
  border-radius: 0 0 20px 20px;
  overflow: hidden;

  &.slide-animation {
    z-index: 2;
    opacity: 1;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }

  &.slide-up {
    animation-name: slide-up;
  }

  &.slide-down {
    animation-name: slide-down;
  }

  &.show {
    z-index: 1;
    opacity: 1;
  }

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 0 0 30px 30px;
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes slide-down {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }
`;

export const ScrollButtonContainer = styled.div`
  position: absolute;
  bottom: 23px;
  // width: 100%;
  left: 47%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: ${breakpoints.tablet}) {
    bottom: 52px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    bottom: 64px;
  }
`;

export const ButtonScroll = styled.div`
  justify-content: center;
  display: flex;
  transform: scale(0.7);

  @media (min-width: ${breakpoints.desktop}) {
    transform: scale(1);
  }
`;

export const ScrollStickContainer = styled.div`
  position: absolute;
  bottom: 35px;
  right: 21px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (min-width: ${breakpoints.tablet}) {
    bottom: 64px;
    right: 27px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    right: 35px;
  }
`;

export const OverScrollDisabled = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`;
